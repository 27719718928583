.custom-pagination-main-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;

  .page-link {
    // background-color: pink;
    font-size: 0.7rem;
  }
}
