.policydetails-Main-div {
  // .accordion-button:not(.collapsed) {
  //   color: var(--bs-accordion-active-color);
  //   background-color: var(--lgi-yellow);
  //   box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  // }

  // .accordion-body {
  //   padding: 10px !important;
  // }

  // .accordion-flush > .accordion-item > .accordion-header .accordion-button,
  // .accordion-flush
  //   > .accordion-item
  //   > .accordion-header
  //   .accordion-button.collapsed {
  //   font-size: 0.85rem;
  //   font-weight: 600;
  //   border-radius: 0;
  //   border-radius: 14px !important;
  //  background-color: var(--lgi-yellow-80)
  // }

  .policydetails {
    width: 100%;
    padding: 10px, 16px, 10px, 16px;
  }

  .policydetails-text-main-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .policydetails-text-container {
    display: flex !important;
    width: 100%;
    line-height: 1.5;
    align-items: center;
    font-size: 0.9rem;
    justify-content: space-between;
  }

  .policydetails-text-rightside {
    white-space: nowrap;
    // line-height: 20px;
    font-size: 0.9rem;
    color: #1d1b20;
    margin-right: 10px;
    flex: 0 0 40%;
    font-weight: 600;
    opacity: 60%;
    word-wrap: break-word;
  }

  .policydetails-text-leftside {
    font-weight: 600;
    flex: 1;
    // line-height: 20px;
    font-size: 0.8rem;
    color: #1d1b20;
    text-align: end;
    display: flex;
    opacity: 80%;
    word-wrap: break-word;
    justify-content: flex-end;
    left: 0;
  }

  .coverDetails {
    width: 100%;
    padding: 10px, 16px, 10px, 16px;

    .coverDetails-text-rightside {
      white-space: nowrap;
      line-height: 25px;
      font-size: 0.7rem;
      color: #1d1b2099;
      margin-right: 10px;
    }

    .coverDetails-text-leftside {
      font-weight: 500;
      line-height: 25px;
      margin-right: 10px;
      font-size: 0.7rem;
      color: #19171c;
      text-align: end;
    }
  }

  .accordion-flush > .accordion-item > .accordion-header .accordion-button,
  .accordion-flush
    > .accordion-item
    > .accordion-header
    .accordion-button.collapsed {
    font-size: 0.85rem;
    font-weight: 600;
    border-radius: 0;
    border-radius: 14px !important;
    background-color: var(--lgi-yellow-80);
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }
}
.recommended-plan {
  .IC1 {
    // background-color: green;
    // cursor: pointer;
  }
  .title {
    // color: red;
    // font-size: 1.1rem;
    font-size: 0.9rem;
  }
  .IC1:hover {
    // cursor: pointer;
    background-color: var(--lgi-yellow-80); /* Background color on hover */
  }
  .price-tag {
    border: 1px solid rgba(66, 133, 244, 1);
    background-color: rgba(236, 243, 254, 1);
    color: rgba(66, 133, 244, 1);
    border-radius: 10px;
    padding: 5px 10px;
    display: inline-block;

    .premium-na {
      // color: red;
      font-size: 20px;
      width: 70px;
      padding-left: 17px;
    }
  }
  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.5rem;
  }
  .insurance-portal-btn {
    background-color: rgba(66, 133, 244, 1);
    color: white;
    border-radius: 10px;
  }
  // .pre-btn-group{
  //   color: red;
  // }
}

.headline-details {
  color: rgba(66, 133, 244, 1);
  font-weight: 600;
  padding-left: 15px;
  .header-according-action {
    .header-according-action {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.pre-btn-group {
  // background-color: red;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;

.share-btn{
  width: 50px;
  background-color: red;
}
}
