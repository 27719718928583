.create-campaign-template-main-container {

    .templeInputLabel {
        font-size: 12px;
        font-weight: 600;
        margin-left: 8px;
    }

    .saveBtn {
        background-color: var(--lgi-color-blue);
        color: white;
        padding: 2px 30px;
    }

    .discardBtn {
        padding: 2px 30px;
    }
}