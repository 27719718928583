// .custom-menu-container {
//   .dropdown-menu {
//     font-size: 0.8rem;
//     // --bs-dropdown-padding-x: 0 !important;
//     // --bs-dropdown-padding-y: 0 !important;
//   }
//   .dropdown-item:hover {
//     font-size: 0.8rem;
//     background-color: rgba(224, 231, 255, 1);
//   }

//   .dropdown-toggle::after {
//     display: none;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: "";
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
//   }
// }
.custom-menu-container {
  .dropdown-menu {
    font-size: 0.8rem;
    // --bs-dropdown-padding-x: 0 !important;
    // --bs-dropdown-padding-y: 0 !important;

    i {
      font-size: 0.8rem;
    }
  }

  .bi-three-dots-vertical {
    font-size: 1rem;
  }

  .dropdown-menu > *:hover {
    font-size: 0.8rem;
    background-color: rgba(224, 231, 255, 1);
  }

  .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}
