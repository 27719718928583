.inbound-main {
  .qutation-btn {
    gap: 8px;
    background-color: white !important;
    color: black !important;
    font-weight: 500;
    border-radius: 10px;
    font-size: 0.8rem;
    border: 1px solid rgba(29, 27, 32, 0.40) !important;
    .bi-card-list{
      color: rgba(66, 133, 244, 1);
      font-weight: bold;
    }
  }
  .download-btn {
    background-color: white !important;
    color: black !important;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid rgba(29, 27, 32, 0.40) !important;
    gap: 8px;
    font-size: 0.8rem;
    .bi-download{
      color: rgba(66, 133, 244, 1);
      font-weight: bold;
    }
    
  }
}
