// .custom-modal {
//   z-index: 100;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .custom-modal-content {
//     background-color: #fff;
//     border-radius: 17px;
//     padding: 1rem;
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add box shadow for depth */
//     width: 800px; /* Set modal width */
//     /* Remove fixed height */
//     /* height: 600px; */
//     max-height: 600px;
//     overflow-y: auto;
//     overscroll-behavior: inherit;
//     /* background-color: red */

//     .custom-modal-header {
//       display: flex;
//       // justify-content: space-between;
//       align-items: center;
//       // border-bottom: 1px solid #ccc; /* Add border bottom for header */
//       padding-bottom: 10px;
//       margin-bottom: 10px;
//       justify-content: center;
//     }

//     .custom-modal-header h5 {
//       margin: 0;
//     }

//     .custom-modal-close {
//       background: none;
//       border: none;
//       font-size: 1rem;
//       cursor: pointer;
//       outline: none;
//     }

//     .custom-modal-footer {
//       margin-top: 5px;
//       display: flex;
//       justify-content: flex-end;
//       gap: 5px;

//       .footer-closed {
//         background-color: rgba(240, 234, 251, 1);
//         color: rgba(74, 79, 196, 1);
//         padding: 0.2rem 1rem 0.2rem 1rem;
//         border-radius: 12px;
//         font-size: 0.8rem;
//         height: 2rem;
//         border: 1px solid rgba(29, 27, 32, 0.6);
//       }
//       .footer-save {
//         background-color: rgba(74, 79, 196, 1);
//         color: white;
//         padding: 0.2rem 1rem 0.2rem 1rem;
//         border-radius: 12px;
//         font-size: 0.8rem;
//         height: 2rem;
//       }
//     }
//   }
// }


// ------------Chat gpt--------

// .custom-modal {
//   z-index: 99999;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // padding-top: 8rem;

//   .custom-modal-content {
//     max-height: 100vh !important;
//     background-color: #fff;
//     border-radius: 8px;
//     top: 20px;
//     padding: 1rem;
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add box shadow for depth */
//     width: 800px; /* Set modal width */
//     /* Remove fixed height */
//     /* height: 600px; */
//     max-height: 600px;
//     overflow-y: auto;
//     overscroll-behavior: inherit;
//     /* background-color: red */

//     .custom-modal-header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       border-bottom: 1px solid #ccc; /* Add border bottom for header */
//       padding-bottom: 10px;
//       margin-bottom: 10px;
//       width: 100%;
//     }

//     .custom-modal-body {
//       padding-top: 10px;
//       position: relative;
//     }
//     .custom-modal-header h5 {
//       margin: 0;
//     }

//     .custom-modal-close {
//       background: none;
//       border: none;
//       font-size: 1.2rem;
//       cursor: pointer;
//       outline: none;
//     }

//     .custom-modal-footer {
//       margin-top: 5px;
//       display: flex;
//       justify-content: flex-end;
//       gap: 10px;

//       .footer-closed {
//         background-color: var(--lgi-gray-40);
//         color: var(--lgi-dark-teal);
//         font-weight: 600;
//         padding: 0.2rem 1rem 0.2rem 1rem;
//         border-radius: 1.2rem;
//         font-size: 0.8rem;
//         height: 2rem;
//         border: 1px solid rgba(29, 27, 32, 0.6);
//         width: 80px;
//       }
//       .footer-save {
//         background-color: var(--lgi-yellow);
//         color: var(--lgi-black);
//         font-weight: 600;
//         padding: 0.2rem 1rem 0.2rem 1rem;
//         border-radius: 1.2rem;
//         font-size: 0.8rem;
//         height: 2rem;
//         width: 80px;
//       }
//     }
//   }
// }



// -------------------modal----------

.custom-modal {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 8rem;

  .custom-modal-content:has(.css-1nmdiq5-menu) {
    // max-height: 80vh !important;
    background-color: #fff;
    border-radius: 8px;
    top: 20px;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* Add box shadow for depth */
    width: 800px;
    /* Set modal width */
    /* Remove fixed height */
    /* height: 600px; */
    max-height: 600px;
    overflow-y: auto;
    overscroll-behavior: inherit;
    /* background-color: red */

    // .css-1nmdiq5-menu {
    //   overflow-y: auto;
    // }
  }

  .custom-modal-content {
    max-height: 80vh !important;
    background-color: #fff;
    border-radius: 8px;
    top: 20px;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* Add box shadow for depth */
    width: 800px;
    /* Set modal width */
    /* Remove fixed height */
    /* height: 600px; */
    max-height: 600px;
    overflow-y: auto;
    overscroll-behavior: inherit;
    /* background-color: red */

    // .css-1nmdiq5-menu {
    //   overflow-y: auto;
    // }

    .custom-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      /* Add border bottom for header */
      padding-bottom: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    .custom-modal-body {
      padding-top: 10px;
      position: relative;
    }

    .custom-modal-header h5 {
      margin: 0;
    }

    .custom-modal-close {
      background: none;
      border: none;
      font-size: 1.2rem;
      cursor: pointer;
      outline: none;
    }

    .custom-modal-footer {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .footer-closed {
        background-color: var(--lgi-gray-40);
        color: var(--lgi-dark-teal);
        font-weight: 600;
        padding: 0.2rem 1rem 0.2rem 1rem;
        border-radius: 1.2rem;
        font-size: 0.8rem;
        height: 2rem;
        border: 1px solid rgba(29, 27, 32, 0.6);
        width: 80px;
      }

      .footer-save {
        background-color: var(--lgi-yellow);
        color: var(--lgi-black);
        font-weight: 600;
        padding: 0.2rem 1rem 0.2rem 1rem;
        border-radius: 1.2rem;
        font-size: 0.8rem;
        height: 2rem;
        width: 80px;
      }
    }
  }
}

