.lead-managementMainCard {
  box-shadow: box-shadow 1px 1px 4px 0px #4a4fc440;

  .lead-management-header-row {
    font-size: 0.7rem;
    color: #1d1b2099;
  }

  .lead-management-data-row {
    font-size: 15px;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }

  .custom-button {
    color: rgba(66, 133, 244, 1);
    align-items: center;
    background-color: rgba(236, 243, 254, 1) !important;
    font-size: 10px;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid rgba(66, 133, 244, 1) !important;
    white-space: nowrap;
    padding: 10px;
  }

  .bi-flag-fill {
    color: red;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .lead-details-header-sub-container {
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    gap: 10px;
    .title-btn {
      margin-top: 5px;
      display: flex;
      gap: 1rem;
    }

    .lead-details-header {
      flex: 1;
      padding: 3px;
      padding: 5px;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 768px) {
      padding: 5px 7px;
      font-size: 10px;
    }

    @media screen and (max-width: 576px) {
      padding: 4px 6px;
      font-size: 8px;
    }
  }
}

.call-disposition {
  // background-color: red;
  z-index: 10;
}
.lead-details-header {
  .mobile-no-btn {
    color: rgba(66, 133, 244, 1);
    align-items: center;
    background-color: rgba(236, 243, 254, 1) !important;
    border-radius: 12px;
    border: 1px solid rgba(66, 133, 244, 1) !important;
    white-space: nowrap;
    padding: 7px;
    height: 40px;
  }
  .send-massage-btn {
    color: rgba(66, 133, 244, 1);
    align-items: center;
    background-color: rgba(236, 243, 254, 1) !important;
    gap: 3px;
    border-radius: 12px;
    border: 1px solid rgba(66, 133, 244, 1) !important;
    white-space: nowrap;
    padding: 8px;
  }
  .initiate-call-btn {
    background-color: rgba(66, 133, 244, 1);
    color: white;
    align-items: center;
    gap: 3px;
    border-radius: 12px;
    padding: 10px;
  }
}
.call-details {
  background-color: rgba(237, 243, 254, 1);
  box-shadow: 0px 2px 4px 0px rgba(237, 243, 254, 1);
  font-weight: bold;
}

.select-massage {
  .middle-content {
    .second-content {
      border: 1px solid rgba(220, 211, 238, 1);
      border-radius: 10px;
      width: 230px;
      .check-box {
        border-radius: 15px;
        border: 1px solid #e0e0e0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 200px;
        height: 50px;
        label {
          color: #4b4b4b;
          font-size: 1.2rem;
          margin: 7px;
          padding-top: 8px;
        }
      }
    }
  }
}
.btn-save {
  .save-btn {
    color: rgba(66, 133, 244, 1);
    align-items: center;
    background-color: rgba(236, 243, 254, 1) !important;
    gap: 3px;
    border-radius: 12px;
    border: 1px solid rgba(66, 133, 244, 1) !important;
    white-space: nowrap;
    padding: 10px;
  }
}

.form-button-main {
  .view-template-btn {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(29, 27, 32, 0.5) !important;
    background-color: rgba(240, 234, 251, 1);

    .view-btn {
      color: black;
      font-weight: 500;
    }
  }
  .report-download-btn {
    background-color: rgba(66, 133, 244, 1);
    color: white;
    font-weight: 600;
    width: 100px;
    height: 40px;
  }
}
@media screen and (max-width: 768px) {
  .lead-details-header {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align buttons to the left */
    margin-top: 6px;
  }

  .d-flex {
    flex-direction: row;
  }

  .title-btn {
    margin-top: 10px;
  }
}
