.lead-management-status-flow {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .clip-path-banner {
    // min-width: 120px;
    // max-width: 150px;
    flex: 1;
    min-height: 20px;
    max-height: 50px;
    text-wrap: nowrap;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: (--lgi-blue);
    color: white;
    padding: 7px 16px;
    display: inline-block;
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%);
    font-size: 0.9rem;
  }

  .clip-path-banner:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    clip-path: polygon(0% 0%, 90% 0, 100% 0%, 100% 100%, 0% 100%, 10% 50%);
  }

  .clip-path-banner:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%, 0% 0%);
  }

  .compleated-lead-manager-process {
    // background-color: rgba(58, 154, 81, 1);
    background-color: rgba(58, 154, 81, 1);
    font-weight: 600;
  }

  .ongoing-lead-manager-process {
    background-color: rgba(45, 156, 237, 1);
    font-weight: bold;
    // background-color: var(--lgi-);
  }

  .pending-lead-manager-process {
    color: black;
    font-weight: bold;
    background-color: rgba(232, 232, 247, 1);
    // background-color: var(--bs-gray-200);
  }
}
