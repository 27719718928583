.custom-tabs-main-container {
  padding: 0.6rem 0.6rem 0.6rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .custom-tabs-header {
    display: flex;
    gap: 0.6rem;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: var(--bs-border-radius);
    --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg)
      var(--bs-secondary-bg) var(--bs-border-color);
    --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-link-active-border-color: var(--bs-border-color)
      var(--bs-border-color) var(--bs-body-bg);
  }

  .nav-tabs {
    display: flex;
    border-bottom: transparent;
    flex-wrap: nowrap;
    gap: 10px;
    border-bottom: none;

    .custom-tab-label {
      min-width: 100px;
      gap: 0.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 550;
      .custom-tab-label-text {
        white-space: nowrap;
      }
    }

    .active {
      color: rgba(66, 133, 244, 1);
      transition: color 0.3s;
      border-bottom: none !important;
      border-bottom: 1px solid rgba(66, 133, 244, 1) !important;
      min-width: none;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 760px) {
    .nav-tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
    }
  }
}
