.editleadform {
  padding: auto;
  flex-wrap: wrap;

  .horizontal-radio-group {
    padding: 10px;
    justify-content: space-between;
    gap: 20px;
  }
  // .custom-select .custom-select-input-wrapper .form-control {
  //   font-size: 0.8rem;
  //   color: #1d1b20 !important;
  //   border-radius: 12px;
  //   border: 1px solid #1d1b2040 !important;
  //   box-shadow: 0px 1px 4px 0px #4a4fc440 inset;
  //   &::placeholder {
  //     color: #1d1b2066 !important;
  //   }
  // }
  // .custom-select .select-form-label {
  //   font-size: 0.8rem;
  //   color: #1d1b20 !important;
  // }
  .custom-input-container .large-input {
    height: 42px !important;
    box-shadow: 0px 1px 4px 0px #4a4fc440 inset;
  }
  .custom-select .custom-select-input-wrapper .large-height {
    height: 44px !important;
  }
  .custom-date-input .form-control {
    height: 44px !important;
    font-size: 0.8rem;
    // color: #1d1b20 !important;
    border-radius: 12px !important;
    border: 1px solid #1d1b2040 !important;
    box-shadow: 0px 1px 4px 0px #4a4fc440 inset;
    &::placeholder {
      color: #1d1b2066 !important;
    }
  }
  .form-label-lead {
    p {
      font-size: 1rem;
      font-weight: 500;
      color: #1d1b20cc;
      text-align: left;
      margin-bottom: 20px;
      padding-left: 7px;
    }
  }
  .first-row {
    padding-left: 7px;
    .leadFrom-headings {
      .form-containt {
        font-size: 1rem;
        font-weight: 500;
        color: #1d1b20cc;
        text-align: left;
        margin-bottom: 20px;
        padding-left: 7px;
      }
    }
  }
  .allrow-heading {
    padding-left: 7px;
    p {
      font-size: 1rem;
      font-weight: 500;
      color: #1d1b20cc;
      text-align: left;
      margin-bottom: 0px;
      padding-left: 8px;
    }
  }
  .addleadbtn {
    padding: 10px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .discard-btn {
      background-color: var(--lgi-gray);
      min-width: 90px !important;
      font-size: 0.7rem;
      border-radius: 50px;
    }
    .save-btn {
      background-color: var(--lgi-yellow);
      min-width: 90px !important;
      border-radius: 50px;
      font-size: 0.7rem;
    }
  }
}

.edit-lead-card-container {
  .TranMarktBtn {
    background: var(--lgi-yellow);
    font-size: 12px !important;
    font-weight: 600;
    margin: 5px;

    .bi-plus-lg::before {
      font-size: 12px;
      font-weight: 900 !important;
    }
  }
  .edit-lead-card-container {
    display: flex;
    padding: 0.3rem;

    .edit-lead-card {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      background-color: var(--lgi-yellow);
      width: 100%;
      border-radius: 0.2rem;
      padding: 0.5rem;
      cursor: pointer;

      i::first-line {
        color: var(--lgi-dark-gray);
      }

      .product-name {
        gap: 0.4rem;
        display: flex !important;
        align-items: center;
        font-size: 0.8rem;
      }
    }

    .selected {
      background-color: var(--bs-gray-300);
    }
  }

  .edit-lead-card {
    .accordion-flush > .accordion-item > .accordion-header .accordion-button,
    .accordion-flush
      > .accordion-item
      > .accordion-header
      .accordion-button.collapsed {
      font-size: 0.85rem;
      font-weight: 600;
      border-radius: 10px !important;
      background-color: var(--lgi-yellow);
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
    }

    .pdf-card {
      margin-bottom: 0.2rem;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 0.2rem;
      padding: 0.5rem;
      border: 1px solid var(--lgi-light-border);

      .pdfIcon {
        font-size: 11px;
        font-weight: 600;
        padding: 6px 3px 6px 3px;
        border-radius: 5px;
        margin: 0 8px 0 0;
        color: white;
        background-color: var(--pdf-bg);
        word-spacing: 1px;
      }

      .pdf-title {
        gap: 0.3rem;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        font-size: 0.8rem;
      }

      .view-btn {
        border-radius: 0.2rem;
        color: var(--lgi-dark-teal);
        font-size: 0.8rem;
      }
    }
  }
}
