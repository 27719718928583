.forgot-pass-box {
  width: 60%;
}
.text-black {
  color: #000000;
  font-weight: 600;
}
.text-gray {
  color: #696f79;
}
.font-8 {
  font-size: 1rem !important;
}
