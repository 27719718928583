.form-label {
  font-size: small;
  margin-left: 15px;
  border-radius: 15px !important;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 17px !important;
  height: 47px;
  border-radius: 15px !important;

  // width: 100%;
  //     padding: 10px;
  //     padding-right: 30px;
  //     font-size: 16px;
  //     border: 1px solid #ccc;
  //     border-radius: 10px;
  //     appearance: none; /* Remove default dropdown arrow */
  //     background-color: white;
  //     background-image: url('data:image/svg+xml;utf8,<svg fill="gray" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
  //     background-repeat: no-repeat;
  //     background-position-x: 100%;
  //     background-position-y: 50%;
}
.error-message-dropdown {
  position: absolute;
  color: red;
  font-size: 11px;
  margin-left: 15px;
}
.custom-rounded {
  border-radius: 30px; /* Customize the radius */
}
