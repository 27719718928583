.not-found-404 {
  margin-bottom: 100px;
  max-width: 400px;
  width: 100%;
}

.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  text-align: center;
}

.error-text {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 35px;
  /* color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']}; */
}
