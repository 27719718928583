.right-side {
  background-color: #4285f4;
  height: 133%;
}

.login-box {
  line-height: 18px;
}
.img {
  width: 100%;
}
.img-fluid {
  width: 70%;
  align-items: start;
}

@media (max-width: 768px) {
  .right-side {
    width: 100%;
    height: 53%;
  }
}
