.toast-container {
  position: fixed;
  z-index: 99999;
  padding: 5px;
  top: 50px;
  margin-right: 15px;

  .toast-open-animation {
    float: right;
    transform-origin: 100% 50%;
    animation: zoomIn 0.5s;
  }

  .toast-close-animation {
    float: right;
    transform-origin: 100% 50%;
    animation: zoomOut 0.5s;
  }

  @keyframes main {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .toast {
    padding: 1rem;
    max-width: 600px;
    min-height: 54px;
    max-height: 200px;
    height: auto;
    width: auto;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #020002;
    overflow: hidden;
    background: #ffffff;
    color: black;
    // transition: left 1s;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }

  .zoomIn {
    animation-name: zoomIn;
  }

  @keyframes zoomOut {
    from {
      opacity: 1;
    }

    50% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
      opacity: 0;
    }
  }
}
