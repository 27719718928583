.email-id {
  line-height: 20px !important;
  font-style: italic;
}
.otp-container {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 35px;
  box-shadow: 0px 1px 4px 0px #4a4fc440;
  margin-bottom: 20px;
}

.otp-input-box {
  width: 68px;
  height: 68px;
  text-align: center;
  font-size: 20px;
  border: 2px solid #ddd;
  border-radius: 16px;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-color: #4285f4; // Blue border on focus
  }
}
.disabled {
  color: #1d1b2099 !important;
}
@media (max-width: 768px) {
  .login-box {
    width: 100%;
  }
  .otp-input-box {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    font-size: 15px;
  }
}
