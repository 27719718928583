.table-container {
  background-color: transparent !important;
  overflow: auto hidden;
  padding: 0.4rem 1rem;

  .table {
    border-collapse: separate;
    border-spacing: 0 7px; /* Adjust this value to change the spacing between rows */
  }
  .table th {
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
    padding: 15px 0; /* Adjust this value to change the cell padding */
    background-color: rgba(
      228,
      229,
      246,
      1
    ) !important; /* Header background color */
    color: rgba(29, 27, 32, 0.8); /* Header text color */
  }
  .isRowSelected {
    background-color: #d1e7ff !important; /* Blue tint for selected rows */
  }
  .table td {
    vertical-align: middle;
    padding: 15px; /* Adjust this value to change the cell padding */
    border-top: 1px solid rgba(229, 231, 255, 1);
    border-bottom: 1px solid rgba(229, 231, 255, 1);
    transition: background-color 0.3s ease, border 0.3s ease; /* Smooth transition for border and background on hover */
  }

  .table td:first-child {
    border-top-left-radius: 10px;
    border-left: 1px solid rgba(229, 231, 255, 1);
    border-bottom-left-radius: 10px;
  }

  .table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid rgba(229, 231, 255, 1);
  }

  .custom-table-sub-container {
    border-radius: 10px;
    width: 100%;

    .custom-table-header-container {
      background-color: transparent !important;
      border-radius: 10px;

      .table-collumn-header-container {
        max-width: 450px;
        font-size: 0.9rem;
        background-color: rgba(
          237,
          238,
          250,
          1
        ) !important; /* Header background color */
        color: rgba(29, 27, 32, 0.8);
      }

      .table-collumn-header-container:first-child {
        border-radius: 0.6rem 0 0 0.6rem;
      }
      .table-collumn-header-container:last-child {
        border-radius: 0 0.6rem 0.6rem 0;
      }
    }

    .custom-table-body-container {
      background-color: white;
      .custom-table-row-container {
        border-radius: 40px !important;
        font-size: 0.8rem !important ;
        transition: background-color 0.3s ease;

        /* Smooth transition for hover */

        &:hover {
          background-color: #2b9afc !important; /* Hover background color */
          border: 1px solid rgb(224, 225, 230) !important; /* Border color on hover */
        }
      }

      .table-click-enabled {
        cursor: pointer;
      }
    }
  }

  &::-webkit-scrollbar {
    height: 8px !important; // Height of the horizontal scrollbar
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(
      64,
      128,
      224,
      0.6
    ) !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(
      64,
      128,
      224,
      0.2
    ) !important; // Background of the scrollbar track
  }

  .action-button-container {
    width: 40px;
  }

  .tableActionIcon {
    height: 20px;
    width: 20px;
    margin: 10px;
    cursor: pointer;
  }

  .customTableCheckbox {
    width: 16px;
    cursor: pointer;
    height: 16px;
  }

  .search-icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  .tableSearchContainer {
    border: 1px solid rgba(64, 128, 224, 0.2) !important;
    border-radius: 20px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .borderLightBlue {
    border: 1px solid rgba(64, 128, 224, 0.2) !important;
  }

  .tableSearchTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
  }

  .customDatePickerContainer {
    height: 40px;
    border: 1px solid rgba(64, 128, 224, 0.2) !important;
  }

  .customDateRangePickerItem {
    height: 40px;
    border: 1px solid rgba(64, 128, 224, 0.2) !important;
  }

  .customDateRangePickerItem:focus {
    border: 1px solid rgba(64, 128, 224, 0.2) !important;
  }

  .tableTabContainer {
    border-right: 1px solid rgba(64, 128, 224, 0.2);
    padding: 8px 20px;
    font-weight: 600;
    cursor: pointer;
  }

  .tableSelectredTab {
    background-color: rgba(240, 234, 251, 1);
    border-bottom: 2px solid rgba(64, 128, 224, 1);
  }

  .statusTablesubItem {
    min-width: 100px;
    border-radius: 20px;
    padding: 1px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    overflow: auto !important;
    white-space: nowrap;
  }

  .removreWhitespace {
    overflow: auto !important;
    white-space: nowrap;
  }
}
