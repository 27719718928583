body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9fd !important;
}
// body {
//   font-family: "Roboto";
//   background-color: #291ccc12 !important;
// }
code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  //   monospace;
}

.ck-editor__editable {
  min-height: 200px;
  max-height: 300px;
}
.numberInput::-webkit-inner-spin-button,
.numberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput[type="number"] {
  -moz-appearance: textfield;
}

.basic-single .css-13cymwt-control {
  min-height: 47px;
  border-radius: 12px;
  border: 1px #1d1b2040;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}
.input-form-control {
  min-height: 47px;
  border-radius: 12px;

  border: 1px #1d1b2040;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}

.basic-single .css-hlgwow {
  padding-left: 1rem;
}

.basic-single .css-1jqq78o-placeholder {
  color: #000000;
}

.basic-single .css-tj5bde-Svg {
  fill: #636363;
}

.basic-single .css-t3ipsp-control {
  min-height: 47px;
  border-radius: 15px;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

@media (-webkit-device-pixel-ratio: 1.5) {
  #root {
    zoom: 0.75;
  }
}
