.custom-select-input-wrapper {
  position: relative;
  width: 100%;

  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: white;
    padding: 0 0 0 5px;
    font-size: 0.8rem;
  }

  .custom-select-dropdown {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 0.1rem;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0.8rem;
  }

  .nexted-inner-list {
    width: 100%;
    .custom-select-dropdown {
      position: relative !important;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
      background: white;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-top: 0.1rem;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 0.8rem;
    }

    .list-group-item {
      position: relative;
      display: block;
      padding: 0;
      //  padding: var(--bs-list-group-item-padding-y)
      //   var(--bs-list-group-item-padding-x);
      color: var(--bs-list-group-color);
      text-decoration: none;
      background-color: var(--bs-list-group-bg);
      border: var(--bs-list-group-border-width) solid
        var(--bs-list-group-border-color);

      .d-flex {
        cursor: pointer;
      }
    }
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0;
    //  padding: var(--bs-list-group-item-padding-y)
    //         var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid
      var(--bs-list-group-border-color);

    .d-flex {
      cursor: pointer;
    }

    .custom-select-title {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      padding: var(--bs-list-group-item-padding-y)
        var(--bs-list-group-item-padding-x);
    }
  }
}