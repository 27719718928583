.radio-container {
  .row {
    background-color: rgba(66, 133, 244, 0.1);
    padding: 20px;
    .sub-Container {
    }
  }
}

.required {
  font-size: 0.8rem;
  //   background-color: green;
}
