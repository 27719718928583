.date-input {
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    height: 47px;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.mainContainer .btn-save .save-btn {
  // background-color: red !important;
  display: flex;
  padding: 5px 20px;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: none !important;
  border: none !important;
  background: rgba(66, 133, 244, 1) !important;
  font-weight: normal !important;
  color: aliceblue;
  // gap: 10px;
  // align-self: stretch
  // background-color: pink;
}

.save-btn:disabled{
  opacity: 0.5;
    /* Makes the button look less prominent */
    cursor: not-allowed;
}

.parent .child1 .child2 {
}
