.call-history-icon {
  .cursor-pointer {
    cursor: pointer;
  }

  .play-icon {
    width: 2px;
    background: none;
    border: none;
    padding: 0;
  }

  .button-icon {
    background: none;
    border: none;
    padding: 0;
    font-size: 1rem;
  }

  .button-icon:focus {
    outline: none;
  }
}
.lead-manager-call-history {
  .status-btn {
    align-self: center !important;
    font-weight: 500;
    align-items: "center" !important;
    justify-content: "center" !important;
    width: 10px;
    min-width: 120px !important;
    padding-left: 15px;
    margin-left: 25px;
  }
}

.table-container {
  background-color: transparent !important;
  overflow: auto hidden;
  padding: 0.4rem 1rem;
}
table {
  width: 100%;
  // border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 0 7px;
}
thead {
  font-size: 0.9rem;
  background-color: rgba(228, 229, 246, 1) !important;
  border-radius: 12px; /* Header background color */
  border-radius: 10px;
}
thead th:first-child {
  border-top-left-radius: 10px;
  border-left: 1px solid rgba(229, 231, 255, 1);
  border-bottom-left-radius: 10px; /* Top left corner */
}

thead th:last-child {
  border-top-right-radius: 10px; /* Top right corner */
  border-left: 1px solid rgba(229, 231, 255, 1);
  border-bottom-right-radius: 10px;
}
tbody {
  background-color: white;
  tr {
    border-radius: 40px !important;
    font-size: 0.8rem !important ;
    transition: background-color 0.3s ease, border 0.3s ease !important;
    /* Smooth transition for hover */
    &:hover {
      background-color: rgba(
        237,
        243,
        254,
        1
      ) !important; /* Hover background color */
      border: 1px solid rgb(224, 225, 230) !important; /* Border color on hover */
    }
  }
}
th {
  padding: 25px;
  text-align: left;
  font-size: 0.9rem;
}

table td {
  vertical-align: middle;
  padding: 15px; /* Adjust this value to change the cell padding */
  border-top: 1px solid rgba(229, 231, 255, 1);
  border-bottom: 1px solid rgba(229, 231, 255, 1);
  transition: background-color 0.3s ease, border 0.3s ease;
}
