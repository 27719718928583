.text-gray {
  color: #696f79 !important;
}
.text-gray-80 {
  color: #8692a6;
}
@media (max-width: 768px) {
  .forgot-pass-box {
    width: 100%;
    padding-top: 40px;
  }
}
